import { signalStoreFeature, withState } from '@ngrx/signals';

import { Account } from '@bto/auth/types/account.types';
import { User } from '@bto/auth/types/user.types';
import { when, withReducer } from '@ngrx/signals/events';

import { authApiEvents, authSource } from './auth.events';

const DEFAULT_STATE = {
  user: undefined as User | undefined,
  account: undefined as Account | undefined,
};

export function withAuthReducer() {
  return signalStoreFeature(
    withState(DEFAULT_STATE),
    withReducer(
      authSource,
      when(
        authApiEvents.loginSuccess,
        authApiEvents.loginWithGoogleSuccess,
        authApiEvents.verifyEmailSuccess,
        ({ user }) => ({ user })
      ),
      when(authApiEvents.getAccountSuccess, ({ account }) => ({ account })),
      when(authApiEvents.logoutSuccess, () => DEFAULT_STATE)
    )
  );
}
