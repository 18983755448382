import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { signalStoreFeature } from '@ngrx/signals';
import { switchMap, map, tap } from 'rxjs';

import { AppRouteNames } from '@bto/app.route-names';
import { Events, withEffects } from '@ngrx/signals/events';
import { navigate } from '@shared/utils/route.utils';
import { handlePostRequest, handleRequest } from '@shared/utils/store.utils';

import { authApiEvents, authEvents } from './auth.events';
import { AccountStatusRouteMap } from '../consts/account-status-route.map';
import { AccountHttpService } from '../services/http/account-http.service';
import { AuthHttpService } from '../services/http/auth-http.service';

export function withAuthEffects() {
  return signalStoreFeature(
    withEffects(
      (
        _,
        events = inject(Events),
        userHttpService = inject(AuthHttpService),
        accountHttpService = inject(AccountHttpService),
        router = inject(Router)
      ) => ({
        register$: events
          .on(authEvents.register)
          .pipe(switchMap(handlePostRequest(userHttpService.register)), map(authApiEvents.registerSuccess)),

        login$: events.on(authEvents.login).pipe(
          switchMap(handlePostRequest(userHttpService.login)),
          map(user => authApiEvents.loginSuccess({ user }))
        ),
        loginWithGoogle$: events.on(authEvents.loginWithGoogle).pipe(
          switchMap(handlePostRequest(userHttpService.loginWithGoogle)),
          map(user => authApiEvents.loginWithGoogleSuccess({ user }))
        ),
        loginSuccess$: events
          .on(authApiEvents.loginSuccess, authApiEvents.loginWithGoogleSuccess)
          .pipe(map(() => authEvents.getAccount({ shouldNavigate: true }))),

        logout$: events
          .on(authEvents.logout)
          .pipe(switchMap(handleRequest(userHttpService.logout)), map(authApiEvents.logoutSuccess)),

        logoutSuccess$: events.on(authApiEvents.logoutSuccess).pipe(map(() => navigate(router, AppRouteNames.LANDING))),

        verifyEmail$: events.on(authEvents.verifyEmail).pipe(
          switchMap(handlePostRequest(userHttpService.verifyEmail)),
          map(user => authApiEvents.verifyEmailSuccess({ user }))
        ),

        resetPassword$: events
          .on(authEvents.resetPassword)
          .pipe(switchMap(handlePostRequest(userHttpService.resetPassword)), map(authApiEvents.resetPasswordSuccess)),
        resetPasswordConfirm$: events
          .on(authEvents.resetPasswordConfirm)
          .pipe(
            switchMap(handlePostRequest(userHttpService.confirmPasswordReset)),
            map(authApiEvents.resetPasswordConfirmSuccess)
          ),

        resendVerificationEmail$: events
          .on(authEvents.resendVerificationEmail)
          .pipe(
            switchMap(handlePostRequest(userHttpService.resendVerificationEmail)),
            map(authApiEvents.resendVerificationEmailSuccess)
          ),

        getAccount$: events.on(authEvents.getAccount).pipe(
          switchMap(({ shouldNavigate }) =>
            accountHttpService.getAccount().pipe(
              tap(({ status }) => (shouldNavigate ? navigate(router, AccountStatusRouteMap[status]) : null)),
              map(account => authApiEvents.getAccountSuccess({ account, shouldNavigate }))
            )
          )
        ),
        getAccountUntilSubscribed$: events.on(authEvents.getAccountUntilSubscribed).pipe(
          switchMap(handleRequest(accountHttpService.getAccountUntilSubscribed)),
          map(account => authApiEvents.getAccountSuccess({ account, shouldNavigate: true }))
        ),

        getAccountSuccess$: events
          .on(authApiEvents.getAccountSuccess)
          .pipe(
            tap(({ account, shouldNavigate }) =>
              shouldNavigate ? navigate(router, AccountStatusRouteMap[account.status]) : null
            )
          ),
      })
    )
  );
}
