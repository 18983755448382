import { computed } from '@angular/core';
import { withStorageSync } from '@angular-architects/ngrx-toolkit';
import { signalStore, withComputed, withHooks } from '@ngrx/signals';

import { withAuthEffects } from '@bto/auth/store/auth.effects';
import { authEvents, authSource } from '@bto/auth/store/auth.events';
import { withAuthReducer } from '@bto/auth/store/auth.reducer';
import { SUBSCRIBED_STATUSES } from '@bto/auth/types/account.types';
import { injectStoreWithDispatch } from '@lib/ngrx-signal-redux/inject-dispatch';
import { injectDispatch } from '@ngrx/signals/events';

export const AuthStore = signalStore(
  { providedIn: 'root' },
  withAuthReducer(),
  withAuthEffects(),
  withComputed(store => ({
    isSubscribed: computed(() => (store.account() ? SUBSCRIBED_STATUSES.includes(store.account()!.status) : false)),
  })),
  withStorageSync(authSource),
  withHooks({
    onInit(store) {
      const dispatcher = injectDispatch(authEvents);
      if (store.user()) {
        setTimeout(() => dispatcher.getAccount({ shouldNavigate: false }));
      }
    },
  })
);

export const injectAuthStore = () => injectStoreWithDispatch(AuthStore, authEvents);
