import { HttpClient, HttpParams } from '@angular/common/http';
import { inject } from '@angular/core';
import { map, Observable } from 'rxjs';

import { enableLoader, handleProgressEvents } from '@bto/shared/operators/operators';
import { OperationId } from '@bto/shared/types/loader.types';
import { environment } from 'src/environments/environment';

export abstract class BaseHttpService {
  protected readonly httpClient = inject(HttpClient);

  abstract basePath: string;
  urlSuffix = '/';
  apiUrl = environment.apiUrl;

  public getUri(endpoint: string | string[]): string {
    return (
      [this.apiUrl, this.basePath, ...(Array.isArray(endpoint) ? endpoint : [endpoint])].filter(Boolean).join('/') +
      this.urlSuffix
    );
  }

  protected put<TRequest, TResponse>(
    endpoint: string | string[],
    request: TRequest,
    id: OperationId
  ): Observable<TResponse> {
    return this.httpClient.put<TResponse>(this.getUri(endpoint), request).pipe(enableLoader(id));
  }

  protected post<TRequest, TResponse>(
    endpoint: string | string[],
    request: TRequest,
    id: OperationId
  ): Observable<TResponse> {
    return this.httpClient.post<TResponse>(this.getUri(endpoint), request).pipe(enableLoader(id));
  }

  protected postWithProgress<TRequest, TResponse>(
    endpoint: string | string[],
    request: TRequest,
    onProgressUpdate: (progress: number) => void
  ): Observable<TResponse> {
    return this.httpClient
      .post<TResponse>(this.getUri(endpoint), request, { reportProgress: true, observe: 'events' })
      .pipe(handleProgressEvents(onProgressUpdate));
  }

  protected get<TResponse>(endpoint: string | string[], id: OperationId, params?: HttpParams): Observable<TResponse> {
    return this.httpClient.get<TResponse>(this.getUri(endpoint), { params }).pipe(enableLoader(id));
  }

  protected getBlob(endpoint: string | string[], id: OperationId, params?: HttpParams): Observable<Blob> {
    return this.httpClient.get(this.getUri(endpoint), { params, observe: 'response', responseType: 'blob' }).pipe(
      enableLoader(id),
      map(response => {
        return response.body!;
      })
    );
  }

  protected delete<TResponse>(
    endpoint: string | string[],
    id: OperationId,
    params?: HttpParams
  ): Observable<TResponse> {
    return this.httpClient.delete<TResponse>(this.getUri(endpoint), { params }).pipe(enableLoader(id));
  }

  protected patch<TRequest, TResponse>(
    endpoint: string | string[],
    request: TRequest,
    id: OperationId
  ): Observable<TResponse> {
    return this.httpClient.patch<TResponse>(this.getUri(endpoint), request).pipe(enableLoader(id));
  }
}
