import { AppRouteNames } from '@bto/app.route-names';
import { AccountStatus } from '@bto/auth/types/account.types';

export const AccountStatusRouteMap: Record<AccountStatus, AppRouteNames> = {
  [AccountStatus.NEW]: AppRouteNames.AUTH,
  [AccountStatus.EMAIL_VERIFIED]: AppRouteNames.BILLING,
  [AccountStatus.TRIAL_STARTED]: AppRouteNames.HOME,
  [AccountStatus.TRIAL_ENDED]: AppRouteNames.BILLING,
  [AccountStatus.SUBSCRIPTION_ACTIVE]: AppRouteNames.HOME,
};
