import { Account } from '@bto/auth/types/account.types';
import { LoginRequest, LoginWithGoogleRequest } from '@bto/auth/types/login.types';
import { PasswordResetConfirmRequest, PasswordResetRequest } from '@bto/auth/types/password-reset.types';
import { EmailVerificationRequest, RegistrationRequest, ResendEmailRequest } from '@bto/auth/types/registration.types';
import { User } from '@bto/auth/types/user.types';
import { requestPayload } from '@bto/shared/utils/store.utils';
import { emptyProps, eventGroup, props } from '@ngrx/signals/events';

export const authSource = 'auth';
export const authEvents = eventGroup({
  source: authSource,
  events: {
    register: requestPayload<RegistrationRequest>(),
    login: requestPayload<LoginRequest>(),
    loginWithGoogle: requestPayload<LoginWithGoogleRequest>(),
    logout: emptyProps(),
    verifyEmail: requestPayload<EmailVerificationRequest>(),
    resetPassword: requestPayload<PasswordResetRequest>(),
    resetPasswordConfirm: requestPayload<PasswordResetConfirmRequest>(),
    resendVerificationEmail: requestPayload<ResendEmailRequest>(),
    getAccount: props<{ shouldNavigate: boolean }>(),
    getAccountUntilSubscribed: emptyProps(),
  },
});

export const authApiEvents = eventGroup({
  source: authSource,
  events: {
    registerSuccess: emptyProps(),
    loginSuccess: props<{ user: User }>(),
    loginWithGoogleSuccess: props<{ user: User }>(),
    verifyEmailSuccess: props<{ user: User }>(),
    resetPasswordSuccess: emptyProps(),
    resetPasswordConfirmSuccess: emptyProps(),
    resendVerificationEmailSuccess: emptyProps(),
    getAccountSuccess: props<{ shouldNavigate: boolean; account: Account }>(),
    logoutSuccess: emptyProps(),
  },
});
