import { payload } from '@angular-architects/ngrx-toolkit';
import { EMPTY, Observable, tap } from 'rxjs';

import { handleErrors } from '@bto/shared/operators/operators';
import { BackendError } from '@bto/shared/types/error.types';
import { props } from '@ngrx/signals/events';

export type RequestPayload<Request, Response = any> = {
  request: Request;
} & GetPayload<Response>;

export type GetPayload<Response = any> = {
  skipEffect?: boolean;
  onSuccess?: (response: Response) => void;
  /** Handles the error if error was not handled return false */
  onError?: (error: BackendError) => boolean;
};

export const requestProps = <Request, Response = any>() => props<RequestPayload<Request, Response>>();
export const requestPayload = <Request, Response = any>() => payload<RequestPayload<Request, Response>>();
export const getProps = <Response = any>() => props<GetPayload<Response>>();

export const handleRequest =
  <Response>(processor: () => Observable<Response>) =>
  () =>
    processor().pipe(handleErrors());

type Processor<Request, Response> = ((request: Request) => Observable<Response>) | (() => Observable<Response>);

export const handlePostRequest =
  <Request, Response>(processor: Processor<Request, Response>) =>
  (payload: RequestPayload<Request, Response>) =>
    getProcessorOrMock(processor, payload).pipe(handleErrors(payload.onError), tap(payload.onSuccess));

const getProcessorOrMock = <Request, Response>(
  processor: Processor<Request, Response>,
  payload: RequestPayload<Request, Response>
) => (payload?.skipEffect ? EMPTY : processor(payload.request));
