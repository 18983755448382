import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Account, SUBSCRIBED_STATUSES } from '@bto/auth/types/account.types';
import { repeatUntil } from '@bto/shared/operators/operators';
import { BaseHttpService } from '@bto/shared/services/base-http.service';

@Injectable({ providedIn: 'root' })
export class AccountHttpService extends BaseHttpService {
  basePath = 'account';

  getAccount = (): Observable<Account> => {
    return this.get<Account>('', 'getAccount');
  };

  getAccountUntilSubscribed = (): Observable<Account> => {
    return this.getAccount().pipe(
      repeatUntil(({ status }) => {
        return SUBSCRIBED_STATUSES.includes(status);
      }, 'getAccountUntilSubscribed')
    );
  };
}
