import { HttpErrorResponse, HttpEvent, HttpEventType } from '@angular/common/http';
import { translate } from '@jsverse/transloco';
import { toast } from 'ngx-sonner';
import {
  animationFrameScheduler,
  catchError,
  concatMap,
  EMPTY,
  filter,
  finalize,
  forkJoin,
  from,
  last,
  map,
  Observable,
  repeat,
  takeWhile,
  throttleTime,
  toArray,
  ReplaySubject,
  switchMap,
} from 'rxjs';

import { log } from '@bto/shared';
import { LoaderService } from '@bto/shared/services/loader.service';
import { ProgressService } from '@bto/shared/services/progress.service';
import { OperationId } from '@bto/shared/types/loader.types';
import { BackendError, ErrorResponse } from '@shared/types/error.types';

export const enableLoader =
  (id: OperationId) =>
  <T>(source$: Observable<T>): Observable<T> => {
    const measure = log.measure(id);
    LoaderService.add(id);
    let error: Error;
    return source$.pipe(
      catchError(err => {
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        error = err;
        throw err;
      }),
      finalize(() => {
        LoaderService.end(id);
        ProgressService.end(id);
        if (error) {
          measure.finishWithError(error);
        } else {
          measure.finish();
        }
      })
    );
  };

export const handleProgressEvents =
  (onProgressUpdate: (progress: number) => void) =>
  <T>(source$: Observable<HttpEvent<T>>): Observable<T> =>
    source$.pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.Sent:
            onProgressUpdate(0);
            break;
          case HttpEventType.UploadProgress:
            onProgressUpdate(event.total ? 100 * (event.loaded / event.total) : 0);
            break;
          case HttpEventType.Response:
            onProgressUpdate(100);
            return event.body as T;
        }
        return undefined as T; // does not matter as only last is emitted
      }),
      last()
    );

export const repeatUntil =
  <T>(predicate: (value: T) => boolean, id: OperationId) =>
  (source$: Observable<T>) =>
    source$.pipe(
      repeat({ delay: 1000 }),
      takeWhile(value => !predicate(value), true),
      filter(predicate),
      enableLoader(id)
    );

const mapErrorResponseToBackendError = (error: HttpErrorResponse): BackendError | null => {
  const errorResponse = error.error as ErrorResponse | null;
  if (errorResponse?.['errors']) {
    return errorResponse.errors[0];
  }
  if (error.status === 404) {
    return { code: 'not_found', attr: 'non_field_errors' };
  }
  return null;
};

export const handleErrors =
  (onError?: (error: BackendError) => boolean) =>
  <T>(source$: Observable<T>): Observable<T> =>
    source$.pipe(
      catchError(error => {
        if (error instanceof HttpErrorResponse) {
          const mappedError = mapErrorResponseToBackendError(error);
          if (mappedError) {
            const handled = onError?.(mappedError);
            if (handled) {
              log.warn('Handled error', mappedError);
              return EMPTY;
            }
          }
        }
        log.error('Unknown unhandled error', error);
        toast.error(translate('errors.unknown'), { duration: 8000 });
        return EMPTY;
      })
    );

export const optionalAnimationThrottleTime =
  <T>(throttle: number) =>
  (source: Observable<T>) =>
    throttle ? source.pipe(throttleTime(throttle, animationFrameScheduler)) : source;

export function forkJoinInBatches<T>(tasks: Observable<T>[], batchSize: number): Observable<T[]> {
  const chunks = [];
  for (let i = 0; i < tasks.length; i += batchSize) {
    chunks.push(tasks.slice(i, i + batchSize));
  }

  return from(chunks).pipe(
    concatMap(chunk => forkJoin(chunk)),
    toArray(),
    map(results => results.flat())
  );
}

export function waitUntil<T>(controlObservable: Observable<boolean>) {
  return (source: Observable<T>) =>
    new Observable<T>(observer => {
      const buffer = new ReplaySubject<T>(); // Buffer to store events when paused

      // Subscription to control observable to manage the buffer release
      const controlSubscription = controlObservable
        .pipe(switchMap(canProcess => (canProcess ? buffer.pipe(concatMap(event => [event])) : EMPTY)))
        .subscribe(observer);

      // Subscription to source observable, adding events to buffer
      const sourceSubscription = source.subscribe({
        next: event => buffer.next(event),
        error: err => observer.error(err),
        complete: () => observer.complete(),
      });

      // Cleanup
      return () => {
        controlSubscription.unsubscribe();
        sourceSubscription.unsubscribe();
      };
    });
}
