import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { LoginRequest, LoginWithGoogleRequest } from '@bto/auth/types/login.types';
import { PasswordResetConfirmRequest, PasswordResetRequest } from '@bto/auth/types/password-reset.types';
import { EmailVerificationRequest, RegistrationRequest, ResendEmailRequest } from '@bto/auth/types/registration.types';
import { User } from '@bto/auth/types/user.types';
import { BaseHttpService } from '@bto/shared/services/base-http.service';

@Injectable({ providedIn: 'root' })
export class AuthHttpService extends BaseHttpService {
  basePath = 'auth';

  register = (request: RegistrationRequest) => {
    return this.post<RegistrationRequest, void>('register', request, 'register');
  };

  login = (request: LoginRequest): Observable<User> => {
    return this.post<LoginRequest, User>('login', request, 'login');
  };

  loginWithGoogle = (request: LoginWithGoogleRequest): Observable<User> => {
    return this.post<LoginWithGoogleRequest, User>('providers/google', request, 'login');
  };

  logout = (): Observable<void> => {
    return this.post<void, void>('logout', undefined, 'logout');
  };

  resetPassword = (request: PasswordResetRequest): Observable<void> => {
    return this.post<PasswordResetRequest, void>('password-reset', request, 'passwordReset');
  };

  confirmPasswordReset = (request: PasswordResetConfirmRequest): Observable<void> => {
    return this.post<PasswordResetConfirmRequest, void>('password-reset/confirm', request, 'passwordResetConfirm');
  };

  resendVerificationEmail = (request: ResendEmailRequest): Observable<void> => {
    return this.post<ResendEmailRequest, void>('register/resend-email', request, 'resendEmailVerification');
  };

  verifyEmail = (request: EmailVerificationRequest): Observable<User> => {
    return this.post<EmailVerificationRequest, User>('register/verify-email', request, 'verifyEmail');
  };
}
