export enum AccountStatus {
  NEW = 'new',
  EMAIL_VERIFIED = 'email_verified',
  TRIAL_STARTED = 'trial_started',
  TRIAL_ENDED = 'trial_ended',
  SUBSCRIPTION_ACTIVE = 'subscription_active',
}

export const SUBSCRIBED_STATUSES = [AccountStatus.TRIAL_STARTED, AccountStatus.SUBSCRIPTION_ACTIVE];

export type AccountOwner = {
  email: string;
};

export type Account = {
  status: AccountStatus;
  owner: AccountOwner;
};
